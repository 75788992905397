<template>
  <div>
    <search-user @submit="submitSearch" />
    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>
    <a-table
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    >
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0 " class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0 " :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          :disabled="!isCanUpdateEffective(record.id, record.role_slug)"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <div slot="is_working" slot-scope="is_working, record">
        <a-switch
          :checked="is_working"
          :loading="editingIsWorkingId === record.id"
          @change="(checked) => changeIsWorking(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)" v-if="isCanEdit(record.id, record.role_slug)">编辑</a>
          <a @click="deleteUser(record.id)" v-if="isCanDelete(record.id, record.role_slug)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增用户模态框 -->
    <new-user
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑用户模态框 -->
    <edit-user
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { updateUserEffective, deleteUser, findTenantUsers, updateUserIsWorking } from '@/api/user'
import SearchUser from '@/views/users/tenant/Search'
import { hasPermission } from '@/utils/permission'
import PreviewImage from '@/components/PreviewImage'

export default {
  name: 'UserList',
  components: {
    Pagination,
    PreviewImage,
    SearchUser,
    NewUser: () => import('@/views/users/tenant/New'),
    EditUser: () => import('@/views/users/tenant/Edit')
  },
  data() {
    return {
      query: {},
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingIsWorkingId: 0, // 正在修改是否上班的记录id
      editingRecord: {}, // 正在编辑的记录id
      previewVisible: false,
      previewImages: [],
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '姓名',
          dataIndex: 'emp_name',
          width: 150,
          fixed: 'left'
        },
        {
          title: '联系电话',
          width: 150,
          dataIndex: 'phone_number'
        },
        {
          title: '登录工号',
          width: 220,
          dataIndex: 'emp_no'
        },
        {
          title: '角色',
          width: 100,
          dataIndex: 'role_name'
        },
        {
          title: '工作地点',
          width: 100,
          dataIndex: 'funeral_home_names'
        },
        {
          title: '照片',
          width: 100,
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '状态',
          width: 65,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '是否上班',
          width: 100,
          dataIndex: 'is_working',
          scopedSlots: { customRender: 'is_working' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          width: 180,
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          width: 120,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return true
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    // 是否可以编辑某条记录
    isCanEdit(recordId, recordRole) {
      if (!this.isHasEditPermission) {
        return false
      }
      if (this.$store.getters.userId === recordId) {
        return false
      }
      return true
    },
    // 是否能删除
    isCanDelete(recordId, recordRole) {
      if (!this.isHasEditPermission) {
        return false
      }
      if (this.$store.getters.userId === recordId) {
        return false
      }
      return true
    },

    // 是否可以编辑某条记录有效性
    isCanUpdateEffective(recordId, recordRole) {
      if (!this.isHasEditPermission) {
        return false
      }

      // 不能操作自己
      if (this.$store.getters.userId === recordId) {
        return false
      }

      return true
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },
    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findTenantUsers(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定将账号生效吗?' : '确定失效账号吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateUserEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    },

    changeIsWorking(checked, record) {
      this.editingIsWorkingId = record.id
      updateUserIsWorking(record.id, { is_working: checked })
        .then((res) => {
          this.editingIsWorkingId = 0
          if (res.code === 0) {
            record.is_working = checked
          }
        })
        .catch(() => {
          this.editingIsWorkingId = 0
        })
    },

    deleteUser(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteUser(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
